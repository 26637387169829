import { useCallback, useEffect, useState } from "react";
import {
  Outlet,
  useLoaderData,
  useNavigate,
  useNavigation,
  useOutletContext,
} from "react-router-dom";
import VolumeTable from "../Volume/VolumeTable";
import Information from "./Information";
import { api } from "../../api";
import { AxiosError } from "axios";
import {
  InstanceDataType,
  TagType,
  ILoaderInformationModal,
  VolumeType,
} from "./types";

const getVolumesList = (instanceId: any, region: any) => {
  return api.get(`/instances/${instanceId}/volumes?region=${region}`);
};

const InstanceInformationModal = () => {
  const data = useLoaderData() as ILoaderInformationModal;
  const instanceId = data.instanceId;
  const instanceIp = data.instanceIp;
  const instanceName = data.instanceName;
  const instanceDeployment = data.instanceDeployment;
  const instanceVersion = data.instanceVersion || "";

  const [volumeList, setVolumeList] = useState<VolumeType[]>([]);
  const [suspensionDate, setSuspensionDate] = useState<string>('');
  const [contractStartDate, setContractStartDate] = useState<string>('');
  const [purchasedStorage, setPurchasedStorage] = useState<string>('');
  const [allowedUsers, setAllowedUsers] = useState<string>('');
  const [totaraUpgradeKey, setTotaraUpgradeKey] = useState<string>('');
  const [supportHours, setSupportHours] = useState<string>('');
  const [concurrency, setConcurrency] = useState<string>('');
  const [activeUsers, setActiveUsers] = useState<string>('');
  const [domain, setDomain] = useState<string>('');
  const [lms, setLMS] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [newDescription, setNewDescription] = useState(''); // state to store the new part of the Description
  const [version, setVersion] = useState<string>('');
  const [branch, setBranch] = useState<string>('');
  const [client, setClient] = useState<string>('');
  //const [dbServerType, setDbServerType] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [errorVolumes, setErrorVolumes] = useState<string>();
  const [errorTags, setErrorTags] = useState<string>();

  const [volumeSelected, setVolumeSelected] = useState<VolumeType>({} as VolumeType);

  const navigate = useNavigate();
  const navigation = useNavigation();
  const { region } = useOutletContext<{region: string}>();
  const { handleRebootPending } = useOutletContext<{ handleRebootPending: (instanceId: string, rebootPending: boolean) => void }>();
  console.log("mi region:", region)

  const [isCreating, setIsCreating] = useState<boolean>(true);
  const [isVolumesLoaded, setIsVolumesLoaded] = useState<boolean>(false);

  const resizeVolume = async (
    volume: VolumeType,
    size: number
  ): Promise<void> => {
    // Begin the upload operation
    console.log("Begin the resize volume operation");

    setIsLoading(true); //show loading element

    //new size
    let newSize = volume.size + size;

    console.log("Parameters to Resize volume");
    console.log(instanceId);
    console.log(instanceIp);
    console.log(region);
    console.log(newSize);

    try {
      const { data } = await api.put(
        `/instances/${instanceId}/volumes/${volume.volumeId}?ip=${instanceIp}&size=${newSize}&deviceName=${volume.partition}&region=${region}`
      );
      console.log("response de resize volume: ", data);

      setIsLoading(false); //hide loading element
      if (data.success) {
        console.log(data);
        //if volume is root, then show reboot alert
        if(volume.partition === "/dev/xvda"){
          alert("The volume size has been modified successfully. The instance must be rebooted to apply the changes. Please, click on accept to reload the volumes.");
          handleRebootPending(instanceId, true);
        }
        else
          alert("The volume size has been modified successfully. Please, click on accept to reload the volumes.");

        //then reload volumes from instance
        setIsLoading(true); //show loading element
        const responseVolumesList = await getVolumesList(instanceId, region);
        const result = responseVolumesList.data;
        console.log("volumes list updated: ", result);

        if (result.success) {
          setVolumeList(result.volumes);
        } else {
          setVolumeList([]);
          alert(result.message);
        }
        setIsLoading(false); //hide loading element
      } else {
        alert(data.message);
      }
    } catch (error: any) {
      if (error instanceof AxiosError && error.response) {
        const { data } = error.response;
        if (data.message) alert(data.message);
      } else alert(error.message);

      setIsLoading(false);
    }
  };

  const deleteVolume = async (volumeId: string): Promise<void> => {
    // Begin operation
    console.log("Begin the delete-request volume operation");

    setIsLoading(true); //show loading element

    //otherwise
    try {
      const response = await api.post(`/instances/delete-request`, {
        resources: {
          instanceId: instanceId,
          volumeId: volumeId,
        },
        deleteLMS: true,
        region: region,
      });

      const data = response.data;
      console.log("response: ", data);

      if (response.status === 200 && data.success) {
        alert("The deletion request has been completed successfully.");
      }
    } catch (error: any) {
      if (error instanceof AxiosError && error.response) {
        const { data } = error.response;
        if (data.error && data.message) {
          alert(data.message);
        } else {
          alert("The deletion request could not be completed.");
        }
      } else alert(error.message);
    }

    setIsLoading(false); //hide loading element
  };

  const getLMSInfoForBackup = async (
    instanceId: string,
    volumeDomain: string,
    volumeName: string
  ): Promise<void> => {
    console.log(instanceId, volumeDomain, volumeName);

    var region = window.localStorage.getItem("moodle-console-region");
    region = region ? region : "us-west-2";

    setIsLoading(true);

    try {
      const response = await api.get(
        `/lms-info/${instanceId}?region=${region}&volumeDomain=${volumeDomain}`
      );
      const data = response.data;
      console.log("response getLMSInfoForBackup: ", data);

      setIsLoading(false);
      if (data.success) {
        const instanceData: InstanceDataType = data.instanceData;
        console.log("ya puede iniciar el backup.");
        alert(
          "All the necessary data for the backup process have been obtained. The backup process will begin."
        );
        await beginBackup(region, instanceId, volumeName, instanceData);
      }
    } catch (error: any) {
      if (error instanceof AxiosError && error.response) {
        const { data } = error.response;
        if (data.error && data.message) {
          alert(data.message);
        } else alert("Something went wrong during the process.");
      } else alert(error.message);
      setIsLoading(false);
    }
  };

  const beginBackup = (
    region: string,
    instanceId: string,
    instanceName: string,
    instanceData: InstanceDataType
  ) => {
    return api.post(`/backup/${instanceId}`, {
      region: region,
      instanceName: instanceName,
      instanceData: instanceData,
    });
  };

  const formatDate = (suspensionDate: string) => {
    const date = new Date(`${suspensionDate}T00:00`);

    // Get year, month, and day part from the date
    var year = date.toLocaleString("default", { year: "numeric" });
    var month = date.toLocaleString("default", { month: "2-digit" });
    var day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    var formattedDate = `${year}-${month}-${day}`;
    //console.log("formattedDate: ", formattedDate);
    return formattedDate;
  };

  const getTagValue = (tags: TagType[], key: string) =>
    // Find the tag with the specified key and return the tag's value if found, otherwise, return empty
    tags.find(tag => tag.Key === key)?.Value || '';

  const setTags = useCallback((listTags: TagType[]) => {
    const tagSuspensionDate = getTagValue(listTags, "SuspensionDate");
    if (tagSuspensionDate) {
      const formattedDate = formatDate(tagSuspensionDate);
      setSuspensionDate(formattedDate);
    }
    else{
      setSuspensionDate('');
    } 
    const tagContractStartDate = getTagValue(listTags, "ContractStartDate");
    if (tagContractStartDate) {
      const formattedDate = formatDate(tagContractStartDate);
      setContractStartDate(formattedDate);
    }
    else{
      setContractStartDate('');
    }
    setPurchasedStorage(getTagValue(listTags, "PurchasedStorage"));
    setAllowedUsers(getTagValue(listTags, "ContractedUsers"));
    setSupportHours(getTagValue(listTags, "SupportHours"));
    //setTotaraUpgradeKey(getTagValue(listTags, "Totara_UpgradeKey"));
    setDomain(getTagValue(listTags, "Domain"));
    setDescription(getTagValue(listTags, "Description"));
    setLMS(listTags.find(tag => tag.Key === "LMS")?.Value || 'Moodle');
    setBranch(getTagValue(listTags, "Branch"));
    setClient(getTagValue(listTags, "Client"));
  }, []);
  
  const getVolumeTags = async (volumeSelected: VolumeType): Promise<void> => {

    // Begin getVolumeTags operation
    console.log("Get Volume Tags Operation: ", volumeSelected.client);

    setIsLoading(true); //show loading element

    try {
      const response = await api.get(`/instances/${instanceId}/volumes/${volumeSelected.volumeId}/tags?region=${region}`);

      const data = response.data;
      //console.log("response volume tags: ", data);

      if (response.status === 200 && data.success) {
        
        const volumeTags: TagType[] = data.tags;
        console.log("volume tags: ", volumeTags);
        if(volumeTags && volumeTags.length > 0){
          //console.log('entre aqui')
          setTags(volumeTags);
          //setVolumeSelected(volumeSelected);
        }
      }
    } catch (error: any) {
      if (error instanceof AxiosError && error.response) {
        const { data } = error.response;
        if (data.error && data.message) {
          setErrorTags(data.message);
        } else {
          setErrorTags("An error has occurred. Failed to load volume information.");
        }
      } else setErrorTags(error.message);
    }

    setIsLoading(false); //hide loading element
  };

/*   const getStorageUsage = useCallback((volumeId: string, volumeDeviceName: string) => {
    api
      //.get(`/instances/${instanceId}/volumes/${volumeId}/usage?region=${region}&ip=${instanceIp}&volumeDeviceName=${volumeDeviceName}`)
      .get(`/instances/${instanceId}/volumes/${volumeId}/usage?region=${region}&ip=${instanceIp}&volumeDeviceName=${volumeDeviceName}`)
      .then(({ data }) => {
        console.log("data recibida en useEffect de storage usage: ", data);
        if (data.success) {
          setStorageUsage(data.storageUsage);
        }
        setIsLoading(false); //hide loading element
      })
      .catch((err) => {
        if (err.response) {
          const result = err.response.data as any;
          if (result && result.message) {
            console.log(result.message);
          }
        } else {
          console.log("An error has occurred. Failed to load storage usage.");
        }
        setIsLoading(false); //hide loading element
      });
  }, [instanceId, instanceIp, region]); */

/*  const calculateConcurrencyPercentage = (usersConnected: string, allowedUsers: string) => {
    console.log("usersConnected: ", usersConnected)
    console.log("allowedUsers: ", allowedUsers)

    // Validate if the parameters are valid
    if (usersConnected === '' || allowedUsers === '') return '';
  
    const usersConnectedNumber = parseInt(usersConnected, 10);
    const allowedUsersNumber = parseInt(allowedUsers, 10);
    console.log("usersConnectedNumber: ", usersConnectedNumber)
    console.log("allowedUsersNumber: ", allowedUsersNumber)
  
    // Validate if the parameters are numbers after the conversion
    if (isNaN(usersConnectedNumber) || isNaN(allowedUsersNumber) || allowedUsersNumber === 0) {
      return '';
    }
  
    if (usersConnectedNumber === 0) {
      return '0%';
    }
  
    const percentage = Math.round((usersConnectedNumber * 100) / allowedUsersNumber);
    return `${percentage}%`;
  };

   const getConcurrency = useCallback((volumeId = '', volumeDomain = '') => {
    let url;
    // If the instance is dedicated, the actual concurrency is obtained from the instance
    // If the instance is shared, the actual concurrency is obtained from the volume (LMS)
    if(instanceDeployment === 'dedicated')
      url = `/instances/${instanceId}/users-connected?region=${region}&ip=${instanceIp}&domain=${domain}&lms=${lms.toLowerCase()}`;
    else
      url = `/instances/${instanceId}/volumes/${volumeId}/users-connected?region=${region}&ip=${instanceIp}&domain=${volumeDomain}`
    // Execute the API call to get the concurrency
    console.log("Get Concurrency from: ", url);
    api
      .get(url)
      .then(({ data }) => {
        console.log("data recibida en useEffect de concurrency: ", data);
        if (data.success) {
          const percentage = calculateConcurrencyPercentage(data.usersConnected, allowedUsers);
          setConcurrency(percentage);

          // If the instance is dedicated, then get the DB Server Type
          if(instanceDeployment === 'dedicated')
            setDbServerType(
              data.dbOnSharedRDS === true 
                ? 'Shared RDS' 
                : 'Dedicated RDS'
            );
        } else {
          setConcurrency('');
        }
        
        setIsLoading(false); //hide loading element
      })
      .catch((err) => {
        if (err.response) {
          const result = err.response.data as any;
          if (result && result.message) {
            console.log(result.message);
          }
        } else {
          console.log("An error has occurred. Failed to load actual concurrency.");
        }
        setIsLoading(false); //hide loading element
      });
  }, [instanceId, instanceIp, domain, lms, instanceDeployment, region, allowedUsers]); */

  const getConcurrency = useCallback((volumeDomain = '') => {
    let paramDomain;
    // If the instance is dedicated, use instance domain
    // If the instance is shared, use volume domain
    paramDomain = instanceDeployment === 'dedicated' ? domain : volumeDomain;
    paramDomain = paramDomain.replace(/^https?:\/\//, '');
    const url = `/instances/${instanceId}/${paramDomain}/active-users`;

    // Execute the API call to get the active users and contracted concurrency
    console.log("Get data from: ", url);
    api
      .get(url)
      .then(({ data }) => {
        console.log("data recibida en useEffect de concurrency: ", data);
        // if (data.success && data.activeUsers)
        //   setConcurrency(data.activeUsers);
        // else 
        //   setConcurrency('');
        if (data.success) {
          const activeUsers = data.activeUsers ? data.activeUsers : '';
          setActiveUsers(activeUsers);
          const concurrency = data.concurrency ? data.concurrency : '';
          setConcurrency(concurrency);
        }         
        setIsLoading(false); //hide loading element
      })
      .catch((err) => {
        if (err.response) {
          const result = err.response.data as any;
          if (result && result.message) {
            console.log(result.message);
          }
        } else {
          console.log("An error has occurred. Failed to load actual active users and concurrency.");
        }
        setIsLoading(false); //hide loading element
      });
  }, [instanceId, domain, instanceDeployment]);

  const getTotaraKeyUpgrade = useCallback((volumeDomain = '') => {
    let url;
    // If the instance is dedicated, the Key Upgrade is obtained from the instance
    // If the instance is shared, the Key Upgrade is obtained from the volume (LMS)
    if(instanceDeployment === 'dedicated')
      url = `/instances/${instanceId}/totara-key-upgrade?region=${region}&ip=${instanceIp}&domain=${domain}`;
    else
      url = `/instances/${instanceId}/totara-key-upgrade?region=${region}&ip=${instanceIp}&domain=${volumeDomain}`
    // Execute the API call to get the Key Upgrade
    console.log("Get Key Upgrade from: ", url);
    api
      .get(url)
      .then(({ data }) => {
        //console.log("data recibida en useEffect de Key Upgrade: ", data);
        if (data.success && data.upgradeKey) 
          setTotaraUpgradeKey(data.upgradeKey);
        
        setIsLoading(false); //hide loading element
      })
      .catch((err) => {
        if (err.response) {
          const result = err.response.data as any;
          if (result && result.message) {
            console.log(result.message);
          }
        } else {
          console.log("An error has occurred. Failed to load Totara upgrade key.");
        }
        setIsLoading(false); //hide loading element
      });
  }, [instanceId, instanceIp, domain, instanceDeployment, region]);

  const getVolumeInfo = async (volumeSelected: VolumeType): Promise<void> => {
    // Save object volume selected
    setVolumeSelected(volumeSelected)
    // Save volume version if deployment is shared
    if(instanceDeployment === 'shared')
      setVersion(volumeSelected.version);
    // Get volume tags
    await getVolumeTags(volumeSelected);
    //console.log('volumeSelected: ', volumeSelected)
    if (volumeSelected.lms === "Totara")
      getTotaraKeyUpgrade(volumeSelected.domain);
    // Get volume (LMS) actual acive users and concurrency
    getConcurrency(volumeSelected.domain);
  };

  const updateData = async (newDescription : string) => {
    //console.log('entre en la funcion save data" ', newDescription)
    setIsLoading(true); //show loading element
    
    // save tags
    let tag: TagType = { Key: "", Value: "" };
    let tags: TagType[] = [];

    if (suspensionDate) {
      tag = {
        Key: "SuspensionDate",
        Value: suspensionDate.toString(),
      };
      tags.push(tag);
    }

    if (contractStartDate) {
      tag = {
        Key: "ContractStartDate",
        Value: contractStartDate.toString(),
      };
      tags.push(tag);
    }

    if (purchasedStorage) {
      tag = {
        Key: "PurchasedStorage",
        Value: purchasedStorage.toString(),
      };
      tags.push(tag);
    }

    if (allowedUsers) {
      tag = {
        Key: "ContractedUsers",
        Value: allowedUsers.toString(),
      };
      tags.push(tag);
    }

    if (supportHours) {
      tag = {
        Key: "SupportHours",
        Value: supportHours.toString(),
      };
      tags.push(tag);
    }

    if (branch) {
      tag = {
        Key: "Branch",
        Value: branch.toString(),
      };
      tags.push(tag);
    }

    if(client) {
      tag = {
        Key: "Client",
        Value: client.toString(),
      };
      tags.push(tag);
    }
    
    if (newDescription) {
      tag = {
        Key: "Description",
        Value: newDescription.toString(),
      };
      tags.push(tag);
      setDescription(newDescription);
      setNewDescription('');
    }

    if (tags.length > 0) { 
      console.log('tags a guardar:', tags)   
      try {
        let response;
        if (instanceDeployment === "shared") {
          //save volume tags
          response = await saveVolumeTags(instanceId, volumeSelected, tags);
        } else { 
          //save instance tags
          response = await saveInstanceTags(instanceId, tags);
        }
        const data = response.data;
        console.log("response de save tags: ", data);

        if (response.status === 201 && data.success)
          alert("The data has been saved successfully.");

        setIsLoading(false); //hide loading element
      } catch (error) {
        if (error instanceof AxiosError && error.response)
          alert(error.response.data.message);
        else 
          alert("An error has occurred. The data could not be saved.");
        setIsLoading(false); //hide loading element
      }
    } else return;
  }

  const saveInstanceTags = (instanceId: string, tags: TagType[]) => {
    return api.post(`/instances/${instanceId}/tags`, {
      tags: tags,
      region: region,
    });
  };
  
  const saveVolumeTags = (instanceId: string, volume: VolumeType, tags: TagType[]) => {
    return api.post(`/instances/${instanceId}/volumes/${volume.volumeId}/tags`, {
      tags: tags,
      region: region,
    });
  };

  const onSubmitUpdate = (newDescription : string) => {
    // validate fields
    if (instanceDeployment === "shared" &&
      (Object.keys(volumeSelected).length === 0) ) {
      alert("Please, you must first load data from a volume.");
      return;
    }
    if (
      (!suspensionDate ||
        (suspensionDate && suspensionDate.toString().trim() === "")) &&
      (!purchasedStorage ||
        (purchasedStorage && purchasedStorage.toString().trim() === "")) &&
      (!allowedUsers || 
        (allowedUsers && allowedUsers.toString().trim() === "")) &&
      (!supportHours ||
        (supportHours && supportHours.toString().trim() === "")) &&
      (!branch ||
        (branch && branch.toString().trim() === "")) &&
      (!client ||
        (client && client.toString().trim() === "")) &&
      (!description ||
        (description && description.toString().trim() === "")) &&
      (!newDescription ||
        (newDescription && newDescription.toString().trim() === ""))
    ) {
      alert("Please, fill in at least one field.");
      return;
    }
    const confirmationQuestion =
      instanceDeployment === "shared"
        ? `Are you sure you want to save the information of the volume "${volumeSelected.client}" with identifier "${volumeSelected.volumeId}"?`
        : `Are you sure you want to save the instance data?`;

    // Show the confirmation box depending on the type of instance (standalone or shared)
    const confirmation = window.confirm(confirmationQuestion);

    if (confirmation)
      updateData(newDescription);
  };

  useEffect(() => {
    if (isCreating) {
      setIsLoading(true); //show loading element
      console.log("isLoading: ", isLoading);

      //Getting instance data, just in case standalone instances
      if(instanceDeployment === "dedicated")
        api
          .get(`/instances/${instanceId}/tags?region=${region}`)
          .then(({ data }) => {
            console.log("data recibida de tags en useEffect: ", data);
            if (data.success) {
              const instanceTags: TagType[] = data.tags;
              setTags(instanceTags);
            }
          })
          .catch((err: AxiosError) => {
            if (err.response) {
              const result = err.response.data as any;
              if (result && result.message) {
                setErrorTags(result.message);
              }
            } else {
              setErrorTags("An error has occurred. Failed to load instance information.");
            }
          });

      // Obteniendo volúmenes
      api
        .get(`/instances/${instanceId}/volumes?region=${region}`)
        .then(({ data }) => {
          //console.log("data recibida en useEffect de volumes: ", data);
          if (data.success) {
            setVolumeList(data.volumes);
            setIsVolumesLoaded(true); // then call endpoint to get storage usage
          }
          setIsLoading(false); //hide loading element
        })
        .catch((err) => {
          if (err.response) {
            const result = err.response.data as any;
            if (result && result.message) {
              setErrorVolumes(result.message);
            }
          } else {
            setErrorVolumes("An error has occurred. Failed to load volumes.");
          }
          setIsLoading(false); //hide loading element
        });

      setIsCreating(false);
    }
  }, [isCreating, instanceId, isLoading, region, instanceDeployment, setTags]);

  useEffect(() => {
    //Get totara upgrade key if LMS is totara and standalone instance
    if(lms === "Totara" && instanceDeployment === "dedicated"){
      getTotaraKeyUpgrade();
    }
  }
  , [lms, getTotaraKeyUpgrade, instanceDeployment]);
 
  useEffect(() => {
    if(isVolumesLoaded && instanceDeployment === "dedicated") { //just in case if instance is standalone and volumes are loaded
      console.log('Getting instance storage usage...')
      setIsLoading(true); //show loading element

      // Obteniendo concurrencia actual
      getConcurrency();

      setIsVolumesLoaded(false);
    }
  }, [isVolumesLoaded, instanceDeployment, getConcurrency]);

  return (
    <>
      <div className="modal-overlay">
        <div
          className={
            isLoading || navigation.state === "submitting" ? "loading" : ""
          }
        ></div>
        <div className="modal-information">
          <button
            className="close-modal-button"
            onClick={() => {
              //navigate(-1);
              navigate("/home/instances")
            }}
          >
            x
          </button>
          <h4 className="title-modal-information">Information</h4>
          <div className="modal-actions-body">
            <div className="section-modal">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>
                    {`${instanceDeployment}` === "shared"
                      ? (volumeSelected && volumeSelected.client ? `${instanceName} / ${volumeSelected.client}` : `${instanceName}`)
                      : `${instanceName}`
                    } 
                  </h5>
                </div>
              </div>
              <div className="section-content volumes">
                {errorTags ? (
                  <div className="pathMessage">
                    <p>{errorTags}</p>
                  </div>
                ) : (
                  <Information 
                    suspensionDateState={{suspensionDate, setSuspensionDate}}
                    contractStartDateState={{contractStartDate, setContractStartDate}}
                    purchasedStorageState={{purchasedStorage, setPurchasedStorage}}
                    allowedUsersState={{allowedUsers, setAllowedUsers}}
                    supportHoursState={{supportHours, setSupportHours}}
                    descriptionState={{description, newDescription, setNewDescription}}
                    branchState={{branch, setBranch}}
                    clientState={{client, setClient}}
                    totaraUpgradeKey={totaraUpgradeKey}
                    activeUsers={activeUsers}
                    concurrency={concurrency} 
                    domain={domain}
                    lms={lms}
                    version={instanceDeployment === 'dedicated' ? instanceVersion : version}                    
                    onSubmitUpdate={onSubmitUpdate}
                    instanceDeployment={instanceDeployment}
                    dbServerType={instanceDeployment === 'shared' ? 'Shared RDS' : ''}    
                  />
                )}
              </div>
            </div>
            <hr className="divisor-modal" />
            <div className="section-modal">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>Volumes</h5>
                </div>
              </div>
              <div className="section-content volumes">
                {errorVolumes ? (
                  <div className="pathMessage">
                    <p>{errorVolumes}</p>
                  </div>
                ) : (
                  <VolumeTable
                    volumes={volumeList}
                    instanceIp={instanceIp}
                    instanceDeployment={instanceDeployment}
                    onResize={resizeVolume}
                    onDelete={deleteVolume}
                    onBackup={getLMSInfoForBackup}
                    onVolumeTags={getVolumeInfo}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {<Outlet context={region}/>}
      </div>
    </>
  );
};

export default InstanceInformationModal;
