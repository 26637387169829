import React, { useState } from "react";

interface ColorPickerProps {
    name?: string; // Nombre del input (opcional)
}

const ColorPicker: React.FC<ColorPickerProps> = ({ name = "color" }) => {
  const [color, setColor] = useState<string>('');

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = event.target.value;
    const hexPattern = /^#([0-9A-Fa-f]{0,6})$/;
  
    if (hexPattern.test(newColor) || newColor === "#") {
        //console.log("newColor: ", newColor);
        setColor(newColor);
    }
  };

  return (
    <>
        <div
          className="color-preview me-1"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "5px",
            backgroundColor: color,
            border: "1px solid #ccc",
          }}
        ></div>
        <input
          className="form-control"
          type="text"
          id={name}
          name={name}
          placeholder="#FFFFFF"
          value={color}
          onChange={handleColorChange}
          maxLength={7}
          style={{ flex: "1" }}
        />
    </>
  );
};

export default ColorPicker;

