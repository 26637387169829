import React, { useState, useRef } from 'react';

interface UploadFileProps {
    name: string; // Input name
    label?: string; // Input name
    acceptTypes: string[]; // Array of accepted file types
    direction?: 'row' | 'column'; // Layout direction
}

const CustomUploadFile: React.FC<UploadFileProps> = ({name, label = 'Upload File', acceptTypes, direction = 'row'}) => {
    const [buttonText, setButtonText] = useState<string>('No file selected');
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            const file = files[0];

            if (!acceptTypes.includes(file.type)) {
                alert(`Please upload a file of type: ${acceptTypes.join(', ')}.`);
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Clear the input
                }
                setButtonText('No file selected'); // Update button text
            } else {
                setButtonText(file.name); // Update button text with file name
            }
        } else {
            setButtonText('No file selected'); // Reset button text
        }
    };

    return (
        <div className="custom-file">
            <label className="form-label" htmlFor={name}>{label}</label>
            <div style={{ display: direction === 'row' ? 'inline' : 'flex', alignItems: 'center' }}>
                <input
                    ref={fileInputRef}
                    className="form-control"
                    type="file"
                    id={name}
                    name={name}
                    accept={acceptTypes.join(',')} 
                    onChange={handleFileChange}
                    style={{ display: 'none' }} // Hide the default input
                />
                <button 
                    type="button" 
                    className="btn btn-secondary" 
                    onClick={() => fileInputRef.current?.click()} // Trigger file input click
                >
                    {buttonText} {/* Use state variable for button text */}
                </button>
            </div>
        </div>
    );
};

export default CustomUploadFile;