import { ActionFunctionArgs, redirect } from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";

const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const installationType = formData.get("flexRadioDefault");
  const installationInstance = formData.get("installationInstance");
  const installationPackage = formData.get("installationPackage");
  const edumyThemeValue = formData.get("edumyThemeCheck");
  const installThemeEdumy =
    edumyThemeValue && edumyThemeValue === "edumyTheme" ? true : false;
  const totaraType = formData.get("totaraType");
  const client = formData.get("client");
  const contractedUsers = formData.get("contractedUsers");
  const branch = formData.get("branch");
  const domain = formData.get("instanceDomain");
  const instanceName = formData.get("instanceName");
  const instanceType = formData.get("instanceType");
  const dbinstance = formData.get("dbinstanceName");
  const volumeSize = formData.get("volumeSize");
  const moodleInstallationType = formData.get("moodleInstallationType");
  const rdsStandaloneValue = formData.get("rdsStandaloneCheck");
  const rdsStandalone = 
    rdsStandaloneValue && rdsStandaloneValue === "rdsStandalone" ? true : false;
  const dbOnSharedRDS = !rdsStandalone;
  const primaryColor = formData.get("primaryColor");
  const secondaryColor = formData.get("secondaryColor");
  const footerColor = formData.get("footerColor");
  const logoDefault = formData.get("logoDefault");
  const logoDark = formData.get("logoDark");
  const clientEmail = formData.get("clientEmail");
  const sendEmailValue = formData.get("sendEmailToClientCheck");
  const sendEmailToClient =
    sendEmailValue && sendEmailValue === "sendEmailToClient" ? true : false;

  console.log("rdsStandaloneValue: ", rdsStandaloneValue);
  console.log("rdsStandalone: ", rdsStandalone)
  console.log("dbOnSharedRDS: ", dbOnSharedRDS)
  console.log("installationType: ", installationType);
  console.log("installationInstance: ", installationInstance);
  console.log("installationPackage: ", installationPackage);
  console.log("edumyThemeValue: ", edumyThemeValue);
  console.log("installThemeEdumy: ", installThemeEdumy);
  console.log("totaraType: ", totaraType);
  console.log("client: ", client);
  console.log("contractedUsers: ", contractedUsers);
  console.log("branch: ", branch);
  console.log("domain: ", domain);
  console.log("instanceName: ", instanceName);
  console.log("instanceType: ", instanceType);
  console.log("dbinstance: ", dbinstance);
  console.log("volumeSize: ", volumeSize);
  console.log("moodleInstallationType: ", moodleInstallationType);
  console.log("logoDefault: ", logoDefault);
  console.log("logoDark: ", logoDark);
  console.log("primaryColor: ", primaryColor);
  console.log("secondaryColor: ", secondaryColor);
  console.log("footerColor: ", footerColor);
  console.log("clientEmail: ", clientEmail);
  console.log("sendEmailValue: ", sendEmailValue);

  try {
    const sendformData = new FormData();
    // Asegurarse de que no sea nulo ni indefinido
    if (installationType !== null && installationType !== undefined)
      sendformData.append("installationType", installationType);
    if (installationInstance !== null && installationInstance !== undefined)
      sendformData.append("installationInstance", installationInstance);
    if (installationPackage !== null && installationPackage !== undefined)
      sendformData.append("installationPackage", installationPackage);
    sendformData.append("installThemeEdumy", installThemeEdumy ? "true" : "false");
    if (totaraType !== null && totaraType !== undefined)
      sendformData.append("totaraType", totaraType);
    if (client !== null && client !== undefined)
      sendformData.append("client", client);
    if (contractedUsers !== null && contractedUsers !== undefined)
      sendformData.append("contractedUsers", contractedUsers);
    if (branch !== null && branch !== undefined)
      sendformData.append("branch", branch);
    if (domain !== null && domain !== undefined)
      sendformData.append("domain", domain);
    if (instanceName !== null && instanceName !== undefined)
      sendformData.append("instanceName", instanceName);
    if (instanceType !== null && instanceType !== undefined)
      sendformData.append("instanceType", instanceType);
    if (dbinstance !== null && dbinstance !== undefined)
      sendformData.append("dbinstance", dbinstance);
    if (volumeSize !== null && volumeSize !== undefined)
      sendformData.append("volumeSize", volumeSize);
    if (moodleInstallationType !== null && moodleInstallationType !== undefined)
      sendformData.append("moodleInstallationType", moodleInstallationType);
    sendformData.append("dbOnSharedRDS", dbOnSharedRDS ? "true" : "false");
    sendformData.append("logoDefault", logoDefault ?? "");
    sendformData.append("logoDark", logoDark ?? "");
    sendformData.append("primaryColor", primaryColor ?? "");
    sendformData.append("secondaryColor", secondaryColor ?? "");
    sendformData.append("footerColor", footerColor ?? "");
    if (clientEmail !== null && clientEmail !== undefined)
      sendformData.append("clientEmail", clientEmail);
    sendformData.append("sendEmailToClient", sendEmailToClient ? "true" : "false");

    const response = await api.post(`/instance`, sendformData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

/*     const response = await api.post(`/instance`, {
      logoFile: logoFile,
      installationType: installationType,
      installationInstance: installationInstance,
      installationPackage: installationPackage,
      installThemeEdumy: installThemeEdumy,
      totaraType: totaraType,
      client: client,
      contractedUsers: contractedUsers,
      branch: branch,
      domain: domain,
      instanceName: instanceName,
      instanceType: instanceType,
      dbInstance: dbinstance,
      volumeSize: volumeSize,
      moodleInstallationType: moodleInstallationType,
      dbOnSharedRDS: dbOnSharedRDS,
    }); */

    const data = response.data;

    if (data.success) {
      console.log("response ok: ", data);
      if (data.startCreationProcess && data.deployId && data.deployId !== ''){
        const deployId = data.deployId;
        return { 
          error: false, 
          startCreationProcess: true, 
          deployId: deployId 
        };
      }
      else {
        const message = 
          data.message ? data.message : "No information has been received about the process. Please contact system support.";
        alert(message);
        return { 
          error: false, 
          message: message, 
          startCreationProcess: false, 
          deployId: "",
          blockCreation: data.blockCreation ? data.blockCreation : false
        };
      }
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const response = error.response;
      const { data } = response;

      //not authorized
      if (response.status === 401) return redirect("/");

      //incorrect parameters
      if (response.status === 400 && data.fieldsError) {
        console.log("fieldsErrors: ", data.fields);
        return {
          error: true,
          fieldsErrors: data.fields,
          startCreationProcess: false,
          deployId: "",
        };
      }

      //another error
      alert(data.message);
      return {
        error: true,
        message: data.message,
        startCreationProcess: false,
        deployId: "",
      };
    }
  }
};

export default action;
